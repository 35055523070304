exports.onInitialClientRender = () => {
    //console.log('onInitialClientRender called', window.EDYN_INTERCOM_APP_ID)
    if (window.EDYN_INTERCOM_APP_ID) {
        window.Intercom('boot', {
            app_id: window.EDYN_INTERCOM_APP_ID
        })
    }
}

exports.onRouteUpdate = () =>{
    //console.log('onRouteUpdate called', window.EDYN_INTERCOM_APP_ID)
    if (window.EDYN_INTERCOM_APP_ID) {
        window.Intercom('update')
    }
}
